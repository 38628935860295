require('./bootstrap');


// import Swiper JS
import Swiper, { Navigation, Autoplay } from 'swiper';
// import Swiper styles
//import 'swiper/css';
Swiper.use([Navigation, Autoplay]);

const swiperSections = document.querySelectorAll('.swiper');
if(swiperSections.length) {
    for (let index = 0; index < swiperSections.length; index++) {
        var swiper = new Swiper(swiperSections[index],
            {
                speed: 200,
                autoplay: {
                    delay: 2500
                },
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 3
                    },
                    // wh
                },
                spaceBetween: 16,
                loop: true,
                // Navigation arrows
                //navigation:
            }
        );
    }
}

const swiperPartners = document.querySelectorAll('.swiper-partner');
if(swiperPartners.length) {
    for (let index = 0; index < swiperPartners.length; index++) {
        var swiper = new Swiper(swiperPartners[index],
            {
                speed: 200,
                autoplay: {
                    delay: 2000
                },
                slidesPerView: 3,
                breakpoints: {
                    768: {
                        slidesPerView: 4,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }
                    },
                    1024: {
                        slidesPerView: 6,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }
                    }
                    // wh
                },
                spaceBetween: 16,
                loop: true,
                // Navigation arrows
                //navigation:
            }
        );
    }
}

const swiperFeatures = document.querySelectorAll('.swiper-features');
if(swiperFeatures.length) {
    for (let index = 0; index < swiperFeatures.length; index++) {
        var swiper = new Swiper(swiperFeatures[index],
            {
                speed: 200,
                autoplay: {
                    delay: 2000
                },
                slidesPerView: 2,
                breakpoints: {
                    768: {
                        slidesPerView: 3,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }
                    },
                    1024: {
                        slidesPerView: 4,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }
                    }
                    // wh
                },
                spaceBetween: 16,
                loop: true,
                // Navigation arrows
                //navigation:
            }
        );
    }
}

//Init gallery

// window.onload(function(e) {
//     var galleries = document.querySelectorAll('.gallery');
//     galleries.forEach(gallery => {

//     });
// })

